/*Popups*/

.popup {
    position: absolute;
    left: 50%;
    top: -2000px;
    width: 300px;
    margin: 0 0 0 -150px;
    z-index: 1002;
    background: $base_white_color;
    
    &__overlay {
         display: none;
         position: fixed;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         background: rgba(37,37,37,0.48);
         z-index: 101;
    }
    
    &__close {
		 position: absolute;
		 width: 26px;
		 height: 25px;
         right: 15px;
         top: 17px;
         background: url(../images/icon-close2.png) no-repeat 0 0;
         background-size: contain;
		 cursor: pointer;
		 font-size: 0;
         z-index: 3;
	 }
     
    .title__group {
        margin: 0 0 25px 0;
    }
    .title {
        margin: 0 0 10px 0;
        font-size: 32px;
        font-family: $base_font2_family;
        text-align: center;

        @include mobile {
            font-size: 20px;
        }
    }
    .text {
        font-size: 16px;
        color: #888;
        text-align: center;
    }
    .form {
        &__group {
            margin: 0;
        }
        &__field {
            label.error {
                font-size: 12px;
                padding: 5px 0 0 0;
                text-transform: uppercase;
            }
        }
        &__control {
            display: block;
            width: 100%;
            height: 50px;
            border: none;
            border-bottom: 3px solid #000;
            padding: 0 13px;
            font-size: 16px;
            font-weight: 400;
            font-family: $base_font2_family;

            &::-webkit-input-placeholder {
                 color: #000;
                 font-weight: 300;
            }
            &:-moz-placeholder { 
                 color: #000;
                 font-weight: 300;
            }
            &::-moz-placeholder {
                 color: #000;
                 font-weight: 300;
            }
            &:-ms-input-placeholder {  
                 color: #000;
                 font-weight: 300;
            }

            &:focus {
                border-bottom-color: #dbb089;
            }
            &.error {
                border-bottom-color: red;
            }
        }
        &__bts {
            padding-top: 38px;
        }
    }

     &__menu {
         position: fixed;
         top: 0px;
         left: -300px;
         margin-left: 0px;
         width: 260px;
         height: 100%;
         z-index: 1002;
         background: #fff;
         border-radius: 0px;

         &-inner {
             position: absolute;
             top: 0px;
             left: 0px;
             width: 100%;
             height: 100%;
             overflow: auto;
             padding: 57px 24px;
         }

         .popup__close {
            width: 31px;
            height: 30px;
            background: url(../images/icon-close.png) no-repeat 0 0;
            top: 57px;
            right: 24px;

            &:before, &:after {
                display: none;
            }
         }

         &-title {
            margin: 0 0 30px 0;
            font-size: 31px;
            font-weight: 300;
            font-family: $base_font2_family;
            color: #877865;
         }

         &-item {
             position: relative;
             font-size: 22px;
             font-weight: 400;
             margin: 0 0 20px 0;
             
             &.active {
                 .popup__menu-lnk {
                    color: $base_link_color;
                    text-decoration: none;
                 }
             }
         }
         .popup__menu-lnk {
             color: #000;
             text-decoration: none;
             
             &:hover {
                 color: $base_link_color;
                 text-decoration: none;
             }

             &-dealer {
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: 21px;
                    height: 16px;
                    background: url(../images/icon-menu-dealer.png) no-repeat 0 0;
                    margin-right: 9px;
                }
             }
         }
         
         .phone {
            position: relative;
            display: block;
            margin: 0 0 22px 0;
            line-height: 32px;
            padding: 0 0 0 44px;
            font-size: 22px;
            color: #000;
            text-decoration: none;

            i {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: #877865;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 16px;
                    height: 15px;
                    margin: -7.5px 0 0 -8px;
                    background: url(../images/icon-tel3.png) no-repeat 0 0;
                }
            }
         }
    }
    
    &__dealer {
        width: 386px;
        margin-left: -193px;

        @include mobile {
            width: 300px;
            margin-left: -150px;
        }

        .popup__inner {
            padding: 40px 25px 35px 25px;
        }
    }

    &__cart {
        width: 520px;
        margin-left: -260px;

        @include mobile {
            width: 300px;
            margin-left: -150px;
        }

        .popup__inner {
            padding: 40px 25px 35px 25px;

            @include mobile {
                padding-top: 60px;
            }
        }

        .name {
            color: $base_link_color;
            text-decoration: underline;
        }

        .bts {
            text-align: center;
            padding-top: 20px;

            .btn {
                display: inline-block;
                vertical-align: top;
                font-size: 14px;
                margin: 0 5px;
            }

            .btn__dark {
                background: $base_text_color;
                color: $base_white_color;

                @include mobile {
                    margin-bottom: 10px;
                }

                &:hover {
                    background: #333;
                }
            }

            .btn__cart {
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 13px;
                    width: 28px;
                    height: 25px;
                    background: url(../images/icon-cart2.png) no-repeat 0 0;
                    background-size: contain;
                }
            }
        }
    }
}