/*Variables*/

/*Colors*/
$base_bg: #fff;
$base_bg2: #ececec;
$base_text_color: #000;
$base_link_color: #d38d3c;
$base_hover_color: #dbb089;

$base_blue_color: #3F51B5;
$base_border_color: #f3f4fc;
$base_light_color: #9FA8DA;
$base_white_color: #fff;

$base_dark_color: #00205d;
$base_dark2_color: #011c50;
$base_dark3_color: #001f5a;
$base_dark4_color: #003471;
$base_red_color: #ff0000;

/*Font*/
$base_font_size: 16px;
$base_font_family: 'PF Din Text Comp Pro', sans-serif;
$base_font2_family: 'PF Din Text Cond Pro', sans-serif;

/*Width*/
$base_width: 1220px;