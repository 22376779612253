/*Fonts*/

@font-face {
    font-family: 'PF Din Text Comp Pro';
    src: url('../fonts/PFDinTextCompPro-Italic.woff2') format('woff2'),
        url('../fonts/PFDinTextCompPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PF Din Text Comp Pro';
    src: url('../fonts/PFDinTextCompPro-Bold.woff2') format('woff2'),
        url('../fonts/PFDinTextCompPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PF Din Text Comp Pro';
    src: url('../fonts/PFDinTextCompPro-Regular.woff2') format('woff2'),
        url('../fonts/PFDinTextCompPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PF Din Text Comp Pro';
    src: url('../fonts/PFDinTextCompPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/PFDinTextCompPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PF Din Text Cond Pro';
    src: url('../fonts/PFDinTextCondPro-Light.woff2') format('woff2'),
        url('../fonts/PFDinTextCondPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PF Din Text Cond Pro';
    src: url('../fonts/PFDinTextCondPro-Medium.woff2') format('woff2'),
        url('../fonts/PFDinTextCondPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'PF Din Text Cond Pro';
    src: url('../fonts/PFDinTextCondPro-Regular.woff2') format('woff2'),
        url('../fonts/PFDinTextCondPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'PF Din Text Cond Pro';
    src: url('../fonts/PFDinTextCondPro-Bold.woff2') format('woff2'),
        url('../fonts/PFDinTextCondPro-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}