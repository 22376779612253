/*
* Header
*/

.header {
	position: absolute;
	top: -100px;
	left: 0px;
	width: 100%;
	padding: 30px 0 11px 0;
    z-index: 20;
    transition: 0.5s ease 0s;
    color: $base_white_color;
    opacity: 0;
    transition: 0.5s ease 0s;

    &_fixed {
        position: fixed;
        top: 0px;
        opacity: 1;
        background: rgba(0,0,0,0.8);
    }

    .checkout-checkout & {
        position: fixed;
        top: 0px;
        opacity: 1;
        background: rgba(0,0,0,0.8);
    }

    @include tablet {
        .col-9 {
            display: none;
        }
        .col-2 {
            width: 60%;
        }
        .col-3 {
            width: 40%;
        }
    }

    @include mobile {
        opacity: 1;
        top: 0px;
        padding: 34px 0;

        &_fixed {
            padding: 20px 0;
        }
    }

	&__lang {
        float: left;
        font-size: 16px;
        line-height: 18px;
        margin: -1px 0 0 0;
        font-family: $base_font2_family;

        a {
            color: $base_white_color;
            text-decoration: none;

            &:hover {
                color: $base_hover_color;
            }
        }

        .active {
            a {
                color: $base_hover_color;
            }
        }
    }

    &__phone {
        position: relative;
        float: left;
        line-height: 32px;
        height: 32px;
        margin-left: 50px;
        padding-left: 44px;
        font-size: 25px;

        .header & {
            color: $base_white_color;
            text-decoration: none;
        }

        &-icon {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #877865;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 15px;
                height: 15px;
                margin: -8px 0 0 -8px;
                background: url(../images/icon-tel.png) no-repeat 0 0;
                background-size: contain;
            }
        }
    }

    &__cart {
        position: relative;
        margin: -15px 0 0 0;
        padding-left: 90px;
        color: $base_white_color;
        font-family: $base_font2_family;
        opacity: 1;
        transition: 0.3s ease 0s;

        @include mobile {
            padding-left: 55px;
            max-width: 55px;
            float: right;
            margin-top: -7px;
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        &_empty {
            opacity: 0.1;

            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                background: transparent;
                z-index: 4;
            }
        }

        &-circle {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 63px;
            height: 63px;
            background: $base_white_color;
            border-radius: 50%;

            @include mobile {
                width: 48px;
                height: 48px;
                cursor: pointer;
            }
        }
        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 38px;
            height: 35px;
            margin: -17.5px 0 0 -19px;
            background: url(../images/icon-cart.png) no-repeat 0 0;
            background-size: contain;

            @include mobile {
                width: 29px;
                height: 26px;
                margin: -13px 0 0 -14.5px;
            }
        }
        &-total {
            position: absolute;
            top: -9px;
            right: -9px;
            width: 30px;
            height: 30px;
            background: $base_link_color;
            color: $base_white_color;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            line-height: 32px;
            border-radius: 50%;

            @include mobile {
                width: 22px;
                height: 22px;
                line-height: 24px;
                font-size: 14px;
            }
        }
        &-info {
            margin: -6px 0 0 0;
            float: left;
            width: 100%;

            @include mobile {
                display: none;
            }
        }
        &-text1 {
            font-size: 18px;
            font-weight: 300;
            line-height: 20px;
        }
        &-text2 {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
        }
        &-btn {
            display: block;
            height: 28px;
            line-height: 30px;
            background: $base_link_color;
            color: $base_white_color;
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            text-align: center;
            border-radius: 20px;
            text-transform: uppercase;
            margin: 8px 0 0 0;
            border: none;
            padding: 0;

            .header & {
                color: $base_white_color;
                text-decoration: none;
            }

            &:hover {
                background: $base_hover_color;
                color: $base_white_color;
            }
        }
    }
    
    .mob-menu-btn {
        float: left;
        width: 38px;
        height: 29px;
        background: url(../images/icon-menu.png) no-repeat 0 0;
        background-size: contain;
        margin: 0 23px 0 0;
    }
}