/*Mixins*/

@mixin animate($animation, $duration, $delay, $fill, $count, $easing) {
	 animation-name: $animation;
	 animation-duration: $duration;
	 animation-delay: $delay;
	 animation-fill-mode: $fill;
	 animation-iteration-count: $count;
	 animation-timing-function: $easing;
	 -webkit-animation-name: $animation;
	 -webkit-animation-duration: $duration;
	 -webkit-animation-delay: $delay;
	 -webkit-animation-fill-mode: $fill;
	 -webkit-animation-iteration-count: $count;
	 -webkit-animation-timing-function: $easing;
}

@mixin linear-gradient($direction, $color1, $color2) {
	 background: $color1;
	 background: -webkit-linear-gradient($direction, $color1, $color2);
	 background: -o-linear-gradient($direction, $color1, $color2);
	 background: -moz-linear-gradient($direction, $color1, $color2);
	 background: linear-gradient($direction, $color1, $color2);
}

@mixin linear-gradient-3($direction, $color1, $color2, $color3) {
	 background: $color1;
	 background: -webkit-linear-gradient($direction, $color1, $color2, $color3);
	 background: -o-linear-gradient($direction, $color1, $color2, $color3);
	 background: -moz-linear-gradient($direction, $color1, $color2, $color3);
	 background: linear-gradient($direction, $color1, $color2, $color3);
}

/* Media Query Vars */
$tablet-width: 1279px;
$mobile-width : 740px;

/* Media Query Mixins */

@mixin tablet {
    @media (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin mobile {
	@media (max-width: #{$mobile-width}) {
   		@content;
  	}
}