/*
* Footer
*/

.footer {
	padding: 102px 0 48px 0;
    background: $base_white_color;
    color: $base_text_color;
    font-size: 18px;
    font-family: $base_font2_family;

    @include mobile {
        padding: 35px 0 10px 0;
        font-size: 12px;

        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0;

            .col:nth-child(1) {
                order: 2;
                width: 50%;
                padding: 0;
            }
            .col:nth-child(2) {
                order: 1;
                width: 100%;
                margin-bottom: 25px;
            }
            .col:nth-child(3) {
                order: 3;
                width: 50%;
                padding: 0;
            }
        }
    }
    
    a {
        color: $base_text_color;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
    
    &__logo {
        margin: -40px 0 0 0;
        text-align: center;

        @include mobile {
            margin: 0;
        }

        &-img {
            margin: 0 0 12px 0;
            font-size: 0px;

            img {
                max-width: 100%;

                @include mobile {
                    max-width: 247px;
                }
            }
        }
        &-text {
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0.3em;
        }
    }

    .col:last-child {
        text-align: right;
    }
}