/*
* Checkout Page
*/

.s-checkout {
    padding: 70px 0;
    
    @include tablet {
        padding: 40px 0;
    }

    .section__title {
    	margin-bottom: 40px;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    .col-8 {
    	float: none;
    	margin: 0 auto;
    	margin-bottom: 40px;

        @include tablet {
            width: auto;
        }
    }

    .table-cart {
    	font-size: 18px;
    	width: 100%;
		margin: 0;
		padding: 0;
		border-collapse: collapse;

        @include mobile {
            display: block;
        }

        tbody, thead {
            @include mobile {
                display: block;
            }
        }

    	th {
    		font-weight: 500;
    		font-family: $base_font2_family;
    		text-align: center;
    		text-transform: uppercase;

            @include mobile {
                font-size: 0px;
            }
    	}

        tr {
            @include mobile {
                display: block;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
        }

    	td {
    		padding: 20px;
    		text-align: center;

            @include mobile {
                display: inline-block;
                padding: 10px;
            }

            &.table__image {
                @include mobile {
                    float: left;
                    height: 80px;
                }
            }
            &.table__name {
                @include mobile {
                    padding-bottom: 0px;
                    max-width: 200px;
                    display: block;
                    text-align: left;
                    margin-left: 67px;
                }
            }
            &.table__price {
                @include mobile {
                    display: none;
                }
            }
    		&.table__quantity {
    			button {
    				background: none;
    				border: none;
    				cursor: pointer;
    				font-size: 14px;
    				width: 24px;
    				line-height: 28px;
    				height: 28px;
    				text-align: center;
    				display: inline-block;
    				vertical-align: top;
    			}
    			span {
    				width: 24px;
    				font-size: 24px;
    				line-height: 24px;
    				font-weight: 300;
    			}
    		}

    		&.table__remove {
                @include mobile {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                }

    			button {
    				background: none;
    				border: none;
    				color: #c00;
    				cursor: pointer;
    			}
    		}
    	}
    }

    .table-totals {
    	text-align: center;
    	font-size: 24px;
    	white-space: nowrap;
    	font-family: $base_font2_family;
    	width: 100%;
		margin: 0;
		padding: 0;
		border-collapse: collapse;

        @include mobile {
            font-size: 18px;
        }

    	td {
    		padding: 10px 0;

    		strong {
    			font-weight: 300;
    			display: inline-block;
    			vertical-align: baseline;
    			padding-right: 20px;

                @include mobile {
                    font-size: 14px;
                }

    			&:after {
    				content: ':';
    			}
    		}
    	}
    }
}

#discount {
    margin: 0 0 20px 0;
    text-align: center;

    .panel-heading, .panel-collapse, .form__group, .form__bts {
        display: inline-block;
        vertical-align: top;
        padding: 0;
    }
    .panel-heading {
        padding-right: 20px;

        h4 {
            font-size: 18px;
            font-weight: 400;
            font-family: $base_font2_family;
            margin: 0;
            line-height: 55px;

            @include mobile {
                line-height: normal;
                margin: 0 0 12px 0;
            }
        }
    }
    .form__bts {
        @include mobile {
            padding-top: 15px;
        }

        .btn {
            height: 50px;
            line-height: 50px;
        }
    }
    .error {
        font-size: 12px;
        padding: 5px 0 0 0;
        text-transform: uppercase;
        color: red;
        text-align: left;
    }
}

.payment-info {
    .bts {
        padding: 20px 0 0 0;
        .btn {
            display: block;
            width: 100%;
        }
    }
}