/*
* Home Page
*/

.section {
    position: relative;

    &__title {
        position: relative;
        font-size: 54px;
        font-weight: 400;
        color: $base_text_color;
        font-family: $base_font2_family;
        margin: 0 0 30px 0;
        text-align: center;

        @include mobile {
            font-size: 32px;
            margin-bottom: 20px;
            padding-bottom: 10px;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 152px;
            height: 3px;
            background: #cfcfcf;
            left: 50%;
            margin-left: -76px;

            @include mobile {
                height: 2px;
                width: 115px;
                margin-left: -57.5px;
            }
        }

        &-left {
            text-align: left;

            &:before {
                left: 20px;
                margin-left: 0px;
            }
        }
    }
}

.s-started {
    min-height: 680px;
    height: 100vh;
    background: url(../images/bg-started.jpg) no-repeat center center;
    background-size: cover;

    &.checkout-started {
        min-height: 400px;
        height: auto;

        @include mobile {
            min-height: 280px;
        }

        .s-started__content {
            height: 400px;

            @include mobile {
                height: 280px;
            }
        }
        .s-started__logo {
            margin-bottom: 0px;

            @include mobile {
                margin-bottom: -80px;
            }
        }
    }

    @include mobile {
        min-height: 100vh;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: center;
        color: $base_white_color;
    }

    &__logo {
        margin: 0 0 90px 0;

        @include tablet {
            margin-bottom: 70px;
        }
        @include mobile {
            margin-bottom: 25px;
        }

        &-img {
            margin: 0 0 20px 0;
            font-size: 0px;

            @include mobile {
                margin-bottom: 8px;
            }

            img {
                max-width: 508px;

                @include mobile {
                    max-width: 243px;
                }
            }
        }
        &-text {
            font-size: 32px;
            font-weight: 300;
            letter-spacing: 0.3em;
            font-family: $base_font2_family;

            @include mobile {
                font-size: 15px;
            }
        }
    }
    &__title {
        font-size: 80px;
        font-weight: 400;
        font-family: $base_font2_family;
        line-height: 70px;
        letter-spacing: 0.18em;
        margin: 0 0 20px 0;

        @include tablet {
            font-size: 70px;
            line-height: 60px;
        }
        @include mobile {
            font-size: 26px;
            line-height: 32px;
            margin-bottom: 10px;
        }
    }
    &__title2 {
        font-size: 54px;
        font-weight: 300;
        font-family: $base_font2_family;
        line-height: 70px;
        letter-spacing: 0.18em;
        margin: 0 0 20px 0;

        @include tablet {
            font-size: 35px;
            line-height: 60px;
        }
        @include mobile {
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 0px;
        }

        span {
            display: inline-block;
            vertical-align: baseline;
            border-bottom: 3px solid $base_white_color;
            line-height: 56px;

            @include mobile {
                line-height: 20px;
                border-bottom: none;
            }
        }
    }
    &__title3 {
        font-size: 64px;
        font-weight: 300;
        font-family: $base_font2_family;
        line-height: 90px;
        letter-spacing: 0.18em;

        @include tablet {
            fint-size: 40px;
            line-height: 60px;
        }
        @include mobile {
            font-size: 14px;
            line-height: 28px;
        }
    }

    .btn {
        position: fixed;
        bottom: 54px;
        left: 4%;
        width: 308px;
        height: 63px;
        line-height: 63px;
        border-radius: 32px;
        background: $base_link_color;
        color: $base_white_color;
        text-decoration: none;
        font-size: 18px;
        font-family: $base_font2_family;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 3;

        @include mobile {
            position: absolute;
            width: 230px;
            height: 47px;
            line-height: 47px;
            border-radius: 24px;
            bottom: 40px;
            left: 50%;
            margin-left: -115px;
            font-size: 14px;
        }

        &:hover {
            color: $base_white_color;
            background: $base_hover_color;
        }

        &__icon {
            display: inline-block;
            vertical-align: middle;
            width: 37px;
            height: 26px;
            margin-right: 18px;
            background: url(../images/icon-dealer.png) no-repeat 0 0;
            background-size: contain;

            @include mobile {
                width: 27px;
                height: 19px;
            }
        }
    }
}

.s-benefits {
    background: #ececec;
    padding: 70px 0;

    @include mobile {
        padding: 40px 0;
    }

    &__subtitle {
        font-size: 31px;
        font-weight: 300;
        font-family: $base_font2_family;
        margin: 0 0 10px 0;
        text-align: center;

        @include mobile {
            font-size: 16px;
            line-height: 24px;

            br {
                display: none;
            }
        }
    }

    .benefits-items {
        font-size: 0px;
        text-align: center;
    }
    .benefits-item {
        display: inline-block;
        vertical-align: top;
        width: 33.3%;
        padding: 0 2%;

        @include tablet {
            width: 50%;
        }
        @include mobile {
            width: 100%;
        }

        &__icon {
            display: block;
            position: relative;
            height: 160px;
            background-repeat: no-repeat;
            background-position: center bottom;
            margin: 0 0 25px 0;

            &-1 {
                background-image: url(../images/ben-i-1.png);
            }
            &-2 {
                background-image: url(../images/ben-i-2.png);
            }
            &-3 {
                background-image: url(../images/ben-i-3.png);
            }
        }
        &__name {
            font-size: 27px;
            font-weight: 700;
            font-family: $base_font2_family;
            margin: 0 0 20px 0;

            @include mobile {
                font-size: 20px;
                margin-bottom: 15px;
            }
        }
        &__text {
            font-size: 19px;
            font-weight: 300;
            font-family: $base_font2_family;
            line-height: 27px;
            color: #535353;

            @include mobile {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
}

.s-quality {
    padding: 70px 0;
    min-height: 778px;

    @include mobile {
        padding: 40px 0 60px 0;
        min-height: initial;
    }

    &__image {
        position: absolute;
        right: -154px;
        top: 15px;
        font-size: 0px;

        @include mobile {
            width: 276px;
            right: -122px;
            top: 180px;

            img {
                max-width: 100%;
            }
        }
    }

    .section__title {
        margin-bottom: 115px;
        position: relative;
        z-index: 2;

        @include mobile {
            margin-bottom: 16px;
        }
    }

    .quality-items {
        max-width: 70%;
        position: relative;
        z-index: 2;

        @include mobile {
            max-width: 100%;
        }
    }

    .quality-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 370px;
        margin: 0 0 75px 0;
        padding: 0 30px 0 115px;

        @include mobile {
            padding: 0 0 0 114px;
            width: 100%;
            margin: 0 0 18px 0;
        }

        &:nth-child(4) {
            width: 430px;

            @include mobile {
                width: 100%;
            }
        }

        &__icon {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background: #121212;

            @include mobile {
                width: 68px;
                height: 68px;
                left: 28px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;

                @include mobile {
                    transform: scale(0.73);
                }
            }

            &-1 {
                &:before {
                    width: 46px;
                    height: 52px;
                    background: url(../images/qual-i-1.png) no-repeat 0 0;
                    margin: -26px 0 0 -23px;

                    @include mobile {}
                }
            }
            &-2 {
                &:before {
                    width: 57px;
                    height: 51px;
                    background: url(../images/qual-i-2.png) no-repeat 0 0;
                    margin: -25.5px 0 0 -28.5px;
                }
            }
            &-3 {
                &:before {
                    width: 50px;
                    height: 45px;
                    background: url(../images/qual-i-3.png) no-repeat 0 0;
                    margin: -22.5px 0 0 -25px;
                }
            }
            &-4 {
                &:before {
                    width: 37px;
                    height: 49px;
                    background: url(../images/qual-i-4.png) no-repeat 0 0;
                    margin: -24.5px 0 0 -18.5px;
                }
            }
        }
        &__name {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 31px;
            height: 90px;

            @include mobile {
                font-size: 23px;
                height: 68px;
            }
        }
    }
}

.s-catalog {
    padding: 70px 0;
    //min-height: 997px;
    min-height: 897px;
    background: url(../images/bg-catalog.jpg) no-repeat center center;
    background-size: cover;
    color: #fff;

    @include mobile {
        padding: 50px 0 40px 0;
        background: #141312;
    }

    .section__title {
        color: #fff;

        &:before {
            background: #3a3a3a;
        }
    }

    .cat-tabs__menu {
        margin: 0 0 30px 0;
        font-size: 31px;
        font-weight: 500;
        font-family: $base_font2_family;

        @include mobile {
            font-size: 16px;
            text-align: center;
        }

        a {
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
            color: #d4cdc6;
            text-decoration: none;
            border-bottom: 3px solid transparent;
            padding: 0 0 4px 0;

            @include mobile {
                margin: 0 6px;
            }

            &:hover {
                color: #fff;
                opacity: 0.9;
            }

            &.active {
                color: #dbb089;
                border-bottom-color: #dbb089;
            }
        }
    }

    .cat-tabs__tab {
        display: none;

        &.active {
            display: block;
        }
    }

    .cat-list__carousel {
        margin: 0 0 45px 0;

        .owl-carousel {
            padding: 0 40px;

            @include tablet {
                padding: 0 30px;
            }

            .owl-prev, .owl-next {
                position: absolute;
                top: 50%;
                left: 0px;
                width: 11px;
                height: 21px;
                margin: -32px 0 0 0;
                border: none;
                padding: 0;
                cursor: pointer;
                background: none;

                @include mobile {
                    margin-top: -40px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    background: url(../images/icon-prev2.png) no-repeat 0 0;
                }
            }
            .owl-next {
                left: auto;
                right: 0px;

                &:before {
                    transform: scale(-1);
                }
            }
        }

        .cat-list__image {
            position: relative;
            font-size: 0px;
            margin: 0 0 15px 0;

            img {
                max-width: 100%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                border: 3px solid #dbb089;
                opacity: 0;
                transition: 0.3s ease 0s;
                z-index: 2;
            }
        }

        .cat-list__name {
            font-size: 13px;
            font-family: $base_font2_family;
            color: #fff;
            transition: 0.3s ease 0s;
            text-align: center;

            @include mobile {
                font-size: 11px;
            }
        }

        .item {
            cursor: pointer;

            &.active {
                .cat-list__image {
                    &:before {
                        opacity: 1;
                    }
                }
                .cat-list__name {
                    color: #dbb089;
                }
            }
        }
    }

    .cat-product {
        &__carousel {
            position: relative;

            &[data-slide="9"] {
                &:before {
                    left: 126px;
                }
            }
            &[data-slide="10"] {
                &:before {
                    left: 216px;
                }
            }

            &:before {
                @include mobile {
                    content: '';
                    position: absolute;
                    top: -41px;
                    left: 38px;
                    border: 24px solid transparent;
                    border-bottom: 17px solid #dbb089;
                }
            }

            .owl-carousel {
                padding: 0 89px;

                @include tablet {
                    padding: 0 30px;
                }
            }
            .owl-prev, .owl-next {
                position: absolute;
                top: 50%;
                left: 0px;
                width: 63px;
                height: 77px;
                margin-top: -38.5px;
                border: 2px solid #fff;
                cursor: pointer;
                background: none;

                @include tablet {
                    width: 16px;
                    height: 29px;
                    top: 122px;
                    margin-top: 0px;
                    border: none;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 16px;
                    height: 29px;
                    margin: -14.5px 0 0 -8px;
                    background: url(../images/icon-prev.png) no-repeat 0 0;
                    background-size: contain;
                }
            }

            .owl-next {
                left: auto;
                right: 0px;

                &:before {
                    transform: scale(-1);
                }
            }
        }
        &__image {
            position: relative;
            float: left;
            width: 419px;
            font-size: 0px;

            @include tablet {
                width: 300px;
            }
            @include mobile {
                float: none;

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
            a {
                img {
                    max-width: 100%;
                }
            }
            .easyzoom {
                float: left;
            }
            .easyzoom img {
                display: block;
            }
        }
        &__info {
            margin-left: 444px;

            @include tablet {
                margin-left: 320px;
            }
            @include mobile {
                margin-left: 0px;
                margin-top: 45px;
            }
        }
        &__name {
            font-size: 31px;
            color: #dbb089;
            margin: 0 0 14px 0;

            @include mobile {
                font-size: 23px;
                text-align: center;
            }
        }
        &__text {
            font-size: 16px;
            font-weight: 300;
            font-family: $base_font2_family;
            line-height: 24px;
            color: #d4cdc6;
            margin: 0 0 14px 0;

            @include mobile {
                font-size: 13px;
                line-height: 18px;
            }
        }
        &__chars {
            display: inline-block;
            vertical-align: top;
            padding: 12px 18px 12px 18px;
            background: #101213;
            font-size: 14px;
            font-weight: 300;
            font-family: $base_font2_family;
            color: #e7ddcf;
            margin: 0 0 14px 0;

            li {
                line-height: 21px;

                strong {
                    color: #fff;
                    font-weight: 300;
                }
            }
        }
        &__prices {
            margin: 0 0 14px 0;
        }
        &__quant {
            display: inline-block;
            vertical-align: top;
            margin-right: 24px;
        }
        &__price {
            display: inline-block;
            vertical-align: top;
            line-height: 36px;
            font-size: 26px;
            font-family: $base_font2_family;

            @include mobile {
                font-size: 20px;
            }
        }
        &__bts {
            .btn {
                width: 240px;

                @include mobile {
                    display: block;
                    width: 220px;
                    font-size: 14px;
                    height: 40px;
                    line-height: 42px;
                    margin: 0 auto;
                }

                &__icon {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 13px;
                    width: 28px;
                    height: 25px;
                    background: url(../images/icon-cart2.png) no-repeat 0 0;
                    background-size: contain;
                }
            }
        }
    }
}

.bg-grey {
    background: url(../images/bg-grey.jpg);

    @include mobile {
        background: none;
    }
}

.s-nums {
    padding: 70px 0 40px 0;

    @include mobile {
        padding: 40px 0;
    }

    .nums-items {
        font-size: 0px;
        text-align: center;
    }
    .nums-item {
        display: inline-block;
        vertical-align: top;
        width: 30%;
        padding: 30px 5% 30px 5%;
        margin: 0 0 30px 0;

        @include mobile {
            padding-top: 16px;
            padding-bottom: 16px;
            width: 100%;
            margin-bottom: 0px;
        }

        &:nth-child(4), &:nth-child(5) {
            width: 40%;

            @include mobile {
                width: 100%;
            }

            .nums-item__name {
                padding: 0 15%;

                @include mobile {
                    padding: 0 5%;
                }
            }
        }

        &__val {
            font-size: 70px;
            font-weight: 700;
            line-height: 35px;
            padding: 14px 0 0 0;
            margin: 0 0 20px 0;

            @include tablet {
                font-size: 48px;
            }
            @include mobile {
                padding-top: 5px;
                margin-bottom: 8px;
            }
        }
        &__name {
            font-size: 20px;
            font-weight: 300;
            font-family: $base_font2_family;
        }
    }
}

.s-partners {
    padding: 0 0 45px 0;

    @include mobile {
        padding-bottom: 25px;
    }

    .s-partners__content {
        position: relative;
        border: 5px solid #ccc;
        padding: 0 40px 20px 195px;

        @include tablet {
            padding-bottom: 80px;
        }
        @include mobile {
            padding: 18px 10px 70px 10px;
            border-width: 3px;
        }
    }
    .s-partners__bts {
        position: absolute;
        bottom: -31px;
        left: 50%;
        width: 356px;
        margin-left: -178px;

        @include mobile {
            width: 266px;
            margin-left: -133px;
            bottom: 18px;
        }

        .btn {
            display: block;
            font-size: 18px;

            @include mobile {
                height: 47px;
                line-height: 47px;
                font-size: 14px;
            }

            &__icon {
                display: inline-block;
                vertical-align: middle;
                width: 36px;
                height: 26px;
                background: url(../images/icon-dealer.png) no-repeat 0 0;
                background-size: contain;
                margin-right: 14px;

                @include mobile {
                    width: 28px;
                    height: 19px;
                }
            }
        }
    }
    .s-partners__img {
        position: absolute;
        top: -320px;
        left: -550px;
        font-size: 0px;

        @include mobile {
            display: none;
        }
    }

    .partners-items {
        font-size: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .partners-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 60px 0 0 0;
        padding: 0 0 0 96px;
        width: 458px;
        font-size: 20px;
        font-weight: 300;
        font-family: $base_font2_family;

        @include mobile {
            margin: 0 0 20px 0;
            padding-left: 72px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
        }

        &-1 {
            &:before {
                width: 70px;
                height: 70px;
                top: 13px;
                background: url(../images/partner-i-1.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 53px;
                    height: 52px;
                }
            }
        }
        &-2 {
            &:before {
                width: 65px;
                height: 68px;
                top: 11px;
                background: url(../images/partner-i-2.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 58px;
                    height: 43px;
                }
            }
        }
        &-3 {
            &:before {
                width: 77px;
                height: 57px;
                top: 14px;
                background: url(../images/partner-i-3.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 49px;
                    height: 51px;
                }
            }
        }
        &-4 {
            &:before {
                width: 74px;
                height: 60px;
                top: 7px;
                background: url(../images/partner-i-4.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 56px;
                    height: 45px;
                }
            }
        }

        &__name {
            font-size: 22px;
            font-weight: 700;
            font-family: $base_font2_family;
            margin: 0 0 10px 0;

            @include mobile {
                font-size: 17px;
            }
        }
        &__text {
            font-size: 20px;
            font-weight: 300;

            @include mobile {
                font-size: 15px;
            }
        }
    }
}

.s-videos {
    padding: 60px 0 80px 0;

    @include mobile {
        padding: 40px 0;
    }

    .section__title {
        margin-bottom: 40px;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    .videos-items {
        margin: 0 -13px;
        font-size: 0px;

        &-single {
            .videos-item {
                width: 45%;

                @include tablet {
                    width: 50%;
                }
                @include mobile {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .videos-item {
        display: inline-block;
        vertical-align: top;
        width: 33.3%;
        padding: 0 13px;
        margin-bottom: 26px;
        font-size: 0px;

        @include tablet {
            width: 50%;
        }
        @include mobile {
            width: 100%;
            margin-bottom: 20px;
        }

        a {
            display: block;
            box-shadow: 0 5px 13px rgba(95,86,74,0.4);

            img {
                max-width: 100%;
            }
        }
    }
}

.s-bform {
    padding: 70px 0;
    background: url(../images/bg-bform.jpg);
    color: #fff;

    @include mobile {
        padding: 40px 0;
        background: #141515;
    }

    .section__title {
        color: #fff;
        margin-bottom: 20px;

        &:before {
            background: #3a3a3a;
        }
    }

    .section__subtitle {
        font-size: 26px;
        font-family: $base_font2_family;
        text-align: center;
        color: #fff1e0;
        margin: 0 0 25px 0;

        @include mobile {
            font-size: 16px;
        }
    }

    .bform {
        padding: 0 0 87px 0;

        @include mobile {
            padding-bottom: 48px;
        }

        &__row {
            margin-left: -30px;
            margin-right: -30px;

            @include mobile {
                margin: 0;
            }

            &:before, &:after {
                content: '';
                display: block;
                clear: both;
            }
        }
        &__col {
            padding: 0 30px;
            float: left;

            @include mobile {
                padding: 0;
                float: none;
            }

            &-1 {
                width: 35%;

                @include tablet {
                    width: 50%;
                }
                @include mobile {
                    width: auto;
                }
            }
            &-2 {
                width: 35%;

                @include tablet {
                    width: 50%;
                }
                @include mobile {
                    width: auto;
                }
            }
            &-3 {
                width: 30%;

                @include tablet {
                    width: 100%;
                }
                @include mobile {
                    width: auto;
                }
            }
        }
        &__field {
            position: relative;

            input, textarea {
                display: block;
                width: 100%;
                height: 50px;
                border: none;
                border-bottom: 3px solid #fff;
                padding: 0 13px;
                background: none;
                font-size: 16px;
                font-weight: 300;
                font-family: $base_font2_family;
                color: #fff;
                transition: 0.3s ease 0s;

                @include mobile {
                    height: 38px;
                    font-size: 14px;
                    border-bottom-width: 1px;
                }

                &::-webkit-input-placeholder {
                     color: #fff;
                }
                &:-moz-placeholder { 
                     color: #fff;
                }
                &::-moz-placeholder {
                     color: #fff;
                }
                &:-ms-input-placeholder {  
                     color: #fff;
                }

                &:focus {
                    border-bottom-color: #dbb089;
                }
                &.error {
                    border-bottom-color: red;
                }
            }

            textarea {
                padding: 13px;
                height: 150px;
            }

            label.error {
                display: none !important;
            }

            .jq-selectbox__select {
                background: transparent;
                border-bottom-color: #fff;
                color: #fff;
                font-weight: 300;
            }
            .jq-selectbox__trigger-arrow {
                background: url(../images/select_2.png) no-repeat 0 0;
            }
        }
        &__bts {
            .btn {
                display: block;
                height: 37px;
                line-height: 34px;
                border: 3px solid #fff;
                border-radius: 24px;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                font-family: $base_font2_family;
                text-transform: uppercase;
                background: none;
                margin-top: 113px;

                @include tablet {
                    margin-top: 40px;
                }
                @include mobile {
                    width: 200px;
                    height: 34px;
                    font-size: 14px;
                    margin-top: 20px;
                    padding: 0;
                    border-width: 1px;
                }

                &:hover {
                    background: #fff;
                    color: #000;
                }
            }
        }
        .form__success {
            font-size: 20px;
            text-align: center;
        }
    }

    .binfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include tablet {
            flex-wrap: wrap;
            padding-left: 12px;
        }

        &__col {
            &-1 {
                width: 438px;

                @include tablet {
                    width: 100%;
                    margin-bottom: 35px;
                }
            }
            &-2 {
                @include tablet {
                    width: 100%;
                    margin-bottom: 35px;
                }
            }
            &-3 {
                width: 438px;

                @include tablet {
                    width: 100%;
                }
            }
        }

        &__tel-list {
            position: relative;
            padding-left: 88px;

            @include mobile {
                padding-left: 66px;
            }
        }

        &__tel-icon {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 63px;
            height: 63px;
            border-radius: 50%;
            background: #877865;

            @include mobile {
                width: 47px;
                height: 47px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 29px;
                height: 29px;
                margin: -14.5px 0 0 -14.5px;
                background: url(../images/icon-tel2.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 23px;
                    height: 23px;
                    margin: -11.5px 0 0 -11.5px;
                }
            }
        }

        &__tel-block {
            margin: 0 0 5px 0;
            font-size: 0px;

            &:first-child {
                .binfo__tel-link {
                    &:before {
                        content: '';
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        width: 7px;
                        height: 9px;
                        margin: -4.5px 0 0 0;
                        background: url(../images/icon-tel-arrr.png) no-repeat 0 0;
                    }
                }
            }
        }

        &__tel-link {
            position: relative;
            display: inline-block;
            vertical-align: top;
            font-size: 25px;
            color: #fff;
            text-decoration: none;
            padding: 0 14px 0 0;
            margin: 0 8px 0 0;

            @include mobile {
                font-size: 18px;
                padding-right: 10px;
                margin-right: 6px;
            }
        }

        &__tel-btn {
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
            margin-top: -7px;

            @include mobile {
                margin-right: 4px;
                margin-top: -5px;
            }

            &-1 {
                width: 36px;
                height: 37px;
                background: url(../images/icon-whats.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 27px;
                    height: 28px;
                }
            }
            &-2 {
                width: 34px;
                height: 37px;
                background: url(../images/icon-viber.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 26px;
                    height: 28px;
                }
            }
            &-3 {
                width: 35px;
                height: 37px;
                background: url(../images/icon-tg.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 26px;
                    height: 28px;
                }
            }
        }

        &__social {
            padding: 8px 0 0 0;
            text-align: center;
            font-size: 0px;

            @include tablet {
                text-align: left;
            }

            a {
                display: inline-block;
                vertical-align: top;
                margin: 0 4px;
                width: 55px;
                height: 54px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 0 0;

                @include mobile {
                    width: 41px;
                    height: 41px;
                }

                &.fb {
                    background-image: url(../images/icon-soc-fb.png);
                }
                &.in {
                    background-image: url(../images/icon-soc-in.png);
                }
            }
        }

        &__adr {
            position: relative;
            padding-left: 88px;
            font-size: 26px;
            color: #fff;

            @include mobile {
                padding-left: 66px;
                font-size: 18px;
            }
        }

        &__adr-icon {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 63px;
            height: 63px;
            border-radius: 50%;
            background: #877865;

            @include mobile {
                width: 47px;
                height: 47px;
            }

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 25px;
                height: 33px;
                margin: -16.5px 0 0 -12.5px;
                background: url(../images/icon-loc.png) no-repeat 0 0;
                background-size: contain;

                @include mobile {
                    width: 19px;
                    height: 25px;
                    margin: -12.5px 0 0 -9.5px;
                }
            }
        }
    }
}

.s-map {
    padding: 55px 0 0 0;

    @include mobile {
        padding: 40px 0 0 0;
    }

    .section__title {
        margin-bottom: 35px;

        @include mobile {
            margin-bottom: 27px;
        }
    }

    .map {
        height: 562px;

        @include mobile {
            height: 383px;
        }
    }
}


.s-page {
    padding: 70px 0;
}

/*Animation*/

@media (min-width: 999px) {
    .wow {
        visibility: hidden;
    }
}








