/*
* Home Page
*/

.sd-started {
    height: 648px;
    background: url(../images/sd-started-bg.jpg) no-repeat center center;
    background-size: cover;
    overflow: visible;

    @include tablet {
        height: 560px;
        min-height: initial;
    }
    @include mobile {
        height: 100vh;
    }

    .s-started__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: center;
        color: $base_white_color;

        @include tablet {
            height: 560px;
        }
        @include mobile {
            height: 100vh;
        }
    }

    .s-started__logo {
        margin: 0 0 25px 0;

        &-img {
            margin: 0 0 10px 0;
            font-size: 0px;

            @include mobile {
                margin-bottom: 8px;
            }

            img {
                max-width: 327px;

                @include mobile {
                    max-width: 243px;
                }
            }
        }
        &-text {
            font-size: 20px;
            font-weight: 300;
            letter-spacing: 0.3em;
            font-family: $base_font2_family;

            @include mobile {
                font-size: 15px;
            }
        }
    }
    .s-started__title {
        font-size: 58px;
        font-weight: 400;
        font-family: $base_font2_family;
        line-height: 58px;
        letter-spacing: 0.18em;
        margin: 0 0 20px 0;
        
        @include tablet {
            font-size: 32px;
            line-height: 42px;
        }
        @include mobile {
            font-size: 21px;
            line-height: 32px;
            margin-bottom: 10px;

            br {
                display: none;
            }
        }
    }
    .s-started__title2 {
        font-size: 26px;
        font-weight: 300;
        font-family: $base_font2_family;
        line-height: 26px;
        letter-spacing: 0.18em;
        margin: 0 0 20px 0;

        @include tablet {
            font-size: 20px;
            line-height: 24px;
        }
        @include mobile {
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 30px;
        }

        span {
            font-weight: 500;
            border: none;
            line-height: normal;
        }
    }

    .s-started__image {
        position: relative;
        font-size: 0px;
        text-align: center;
        margin: 0 0 -200px 0;
        z-index: 2;

        @include mobile {
            margin-bottom: -100px;
        }

        img {
            max-width: 100%;
        }
    }
}

.sd-benefits {
    background: #ececec;
    padding: 116px 0 50px 0;

    @include mobile {
        padding: 40px 0 8px 0;
    }

    .bts {
        text-align: center;
        margin: 0 0 62px 0;

        @include mobile {
            margin: -64px 0 40px 0;
        }
    }

    .btn {
        width: 354px;
        height: 63px;
        line-height: 63px;
        border-radius: 32px;
        background: $base_link_color;
        color: $base_white_color;
        text-decoration: none;
        font-size: 18px;
        font-family: $base_font2_family;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 3;

        @include mobile {
            width: 230px;
            height: 47px;
            line-height: 47px;
            border-radius: 24px;
            font-size: 14px;
        }

        &:hover {
            color: $base_white_color;
            background: $base_hover_color;
        }

        &__icon {
            display: inline-block;
            vertical-align: middle;
            width: 37px;
            height: 26px;
            margin-right: 18px;
            background: url(../images/icon-dealer.png) no-repeat 0 0;
            background-size: contain;

            @include mobile {
                width: 27px;
                height: 19px;
            }
        }
    }

    .benefits-items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mobile {
            display: block;
        }
    }
    .benefits-item {
        width: 353px;
        padding: 38px 32px 0;
        min-height: 230px;
        background: #121212;
        margin-bottom: 30px;

        @include tablet {
            width: 230px;
        }
        @include mobile {
            width: 100%;
        }

        &__icon {
            display: block;
            position: relative;
            height: 100px;
            text-align: center;

            &:before {
                content: '';
                display: inline-block;
                vertical-align: top;
            }

            &-1 {
                &:before {
                    width: 47px;
                    height: 77px;
                    background-image: url(../images/d-ben-i-1.png);
                }
            }
            &-2 {
                &:before {
                    width: 63px;
                    height: 59px;
                    background-image: url(../images/d-ben-i-2.png);
                }
            }
            &-3 {
                &:before {
                    width: 49px;
                    height: 57px;
                    background-image: url(../images/d-ben-i-3.png);
                }
            }
        }
        &__name {
            font-size: 21px;
            font-weight: 300;
            font-family: $base_font2_family;
            text-align: center;
            color: #fff;

            @include tablet {
                font-size: 18px;
            }
            @include mobile {
                font-size: 16px;
            }
        }
    }
}

.sd-form {
    padding: 0 0 44px 0;

    .container {
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .section__title {
        margin: 0 -30px;
        padding: 0;

        &:before {
            display: none;
        }
    }

    &__subtitle {
        position: relative;
        font-size: 26px;
        font-weight: 300;
        font-family: $base_font2_family;
        margin: 0 auto 30px auto;
        padding: 0 0 8px 0;
        text-align: center;
        max-width: 860px;

        &:before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 152px;
            height: 3px;
            background: #d5d5d5;
            left: 50%;
            margin-left: -76px;
        }

        @include mobile {
            font-size: 16px;
            line-height: 24px;

            br {
                display: none;
            }
        }
    }

    &__image {
        float: left;
        position: relative;
        font-size: 0px;
        width: 50%;
        min-height: 398px;

        @include tablet {
            float: none;
            width: auto;
            margin-bottom: 30px;
            min-height: 0px;
        }

        img {
            position: absolute;
            top: 25px;
            left: -180px;

            @include tablet {
                position: relative;
                top: auto;
                left: auto;
                max-width: 100%;
            }
        }
    }

    &__form {
        position: relative;
        width: 500px;
        float: right;
        border: 4px solid #d38d3c;
        padding: 38px 32px;

        @include tablet {
            float: none;
            margin: 0 auto;
        }
        @include mobile {
            width: auto;
            padding: 20px 15px;
        }

        .title {
            font-size: 30px;
            font-weight: 500;
            font-family: $base_font2_family;
            text-transform: uppercase;
            margin: 0 0 12px 0;
            text-align: center;
        }
        .subtitle {
            font-size: 18px;
            font-family: $base_font2_family;
            text-align: center;
            margin: 0 0 20px 0;
        }

        form {
            padding-left: 24px;
            padding-right: 52px;

            @include mobile  {
                padding: 0;
            }
        }

        .form__success {
            text-align: center;
        }
    }
}

.d-businnes {
    background: #fff url(../images/bus_bg.jpg) no-repeat top center;
    padding: 70px 0;

    @include mobile {
        padding: 40px 0;
    }

    .section__title {
        margin: 0;
        padding: 0;
        &.lines {
            margin-bottom: 25px;
            &:before {
                content: '';
                position: absolute;
                bottom: 0px;
                width: 152px;
                height: 3px;
                background: #d5d5d5;
                left: 50%;
                margin-left: -76px;
                display: block;
            }
        }
        &:before {
            display: none;
        }
    }

    .s-businnes__subtitle {
        position: relative;
        font-size: 26px;
        font-weight: 300;
        font-family: $base_font2_family;
        margin: 0 0 35px 0;
        padding: 0 0 8px 0;
        text-align: center;
        &:before {
            content: '';
            position: absolute;
            bottom: 0px;
            width: 152px;
            height: 3px;
            background: #d5d5d5;
            left: 50%;
            margin-left: -76px;
        }

        @include mobile {
            font-size: 16px;
            line-height: 24px;

            br {
                display: none;
            }
        }
    }

    .businnes-items {
        margin: 0 -13px;
        margin-bottom: 150px;
        font-size: 0px;
        text-align: center;

        @include tablet {
            margin-bottom: 50px;
        }
        @include mobile {
            margin-bottom: 30px;
        }

        .businnes-col {
            padding: 0 13px 26px 13px;
            display: inline-block;
            vertical-align: top;
            width: 50%;

            @include tablet {
                width: 100%;
            }
        }
    }
    .businnes-item {
        padding: 35px;
        background: #ede0d0;
        min-height: 300px;
        
        &__icon {
            display: block;
            position: relative;
            height: 66px;
            background-repeat: no-repeat;
            background-position: center bottom;
            margin: 0 0 20px 0;

            &-1 {
                background-image: url(../images/bus-i-1.png);
            }
            &-2 {
                background-image: url(../images/bus-i-2.png);
            }
            &-3 {
                background-image: url(../images/bus-i-3.png);
            }
            &-4 {
                background-image: url(../images/bus-i-4.png);
            }
        }
        &__name {
            font-size: 27px;
            font-weight: 700;
            font-family: $base_font_family;
            margin: 0 0 10px 0;

            @include mobile {
                font-size: 20px;
                margin-bottom: 15px;
            }
        }
        &__text {
            font-size: 19px;
            font-weight: 300;
            font-family: $base_font_family;
            line-height: 27px;
            color: #535353;

            @include mobile {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    .differs-items {
        position: relative;
        text-align: center;
        &:before {
            content: '';
            position: absolute;
            left: -100px;
            top: 0;
            width: 704px;
            height: 857px;
            background: url(../images/differs_bg.jpg) no-repeat;
            background-size: contain;
        }
    }
    .differs-item {
        position: relative;
        float: left;

        &__item-1 {
            padding: 50px 30px 0 30px;
            margin-top: 15px;
            width: 350px;
            height: 350px;
            background: #393535;
            border-radius: 350px;
            -webkit-border-radius: 350px;

            @include mobile {
                width: 300px;
                height: 300px;
                margin: 20px 0;
                padding: 30px;
            }
        }
        &__item-2 {
            margin: 45px 0 0 85px;
            padding: 30px 20px 0 20px;
            width: 260px;
            height: 260px;
            background: #675947;
            border-radius: 260px;
            -webkit-border-radius: 260px;

            @include mobile {
                margin: 20px 0;
                width: 300px;
                height: 300px;
                padding: 30px;
            }

            .differs-item__name {
                font-size: 22px;

                @include mobile {
                    font-size: 20px;
                }
            }
            .differs-item__text {
                font-size: 15px;
                line-height: 20px;

                @include mobile {
                    font-size: 16px;
                }
            }
        }
        &__item-3 {
            margin: 0;
            padding: 60px 50px 0 50px;
            float: right;
            width: 440px;
            height: 440px;
            background: #bf783d;
            border-radius: 260px;
            -webkit-border-radius: 260px;

            @include mobile {
                width: 300px;
                height: 300px;
                margin: 20px 0;
                padding: 30px;
            }

            .differs-item__name {
                font-size: 39px;
                line-height: 49px;

                @include mobile {
                    font-size: 20px;
                    line-height: normal;
                }
            }
            .differs-item__text {
                font-size: 21px;
                line-height: 20px;

                @include mobile {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        &__item-4 {
            margin: 40px 0 0 5px;
            padding: 60px 50px 0 50px;
            width: 414px;
            height: 414px;
            background: #1f1c1c;
            border-radius: 414px;
            -webkit-border-radius: 414px;

            @include mobile {
                width: 300px;
                height: 300px;
                margin: 0 auto;
                padding: 30px;
            }

            .differs-item__name {
                font-size: 35px;
                line-height: 45px;

                @include mobile {
                    font-size: 20px;
                    line-height: normal;
                }
            }
            .differs-item__text {
                font-size: 18px;
                line-height: 24px;

                @include mobile {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        @include tablet {
            
        }
        @include mobile {
            
        }
        &__icon {
            display: block;
            position: relative;
            height: 71px;
            background-repeat: no-repeat;
            background-position: center bottom;
            margin: 0 0 20px 0;

            &-1 {
                height: 71px;
                background-image: url(../images/differs_ic1.png);
            }
            &-2 {
                height: 48px;
                background-image: url(../images/differs_ic2.png);
            }
            &-3 {
                height: 166px;
                background-image: url(../images/differs_ic3.png);

                @include mobile {
                    height: 110px;
                    background-size: contain;
                }
            }
            &-4 {
                height: 103px;
                background-image: url(../images/differs_ic4.png);

                @include mobile {
                    height: 64px;
                    background-size: contain;
                }
            }
        }
        &__name {
            font-size: 26px;
            font-weight: 700;
            font-family: $base_font_family;
            color: #fff;
            margin: 0 0 5px 0;

            @include mobile {
                font-size: 20px;
                margin-bottom: 5px;
            }
        }
        &__text {
            font-size: 18px;
            font-weight: 400;
            font-family: $base_font_family;
            line-height: 27px;
            color: #fff;

            @include mobile {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}