/*Defaults*/

*, *:before, *:after {
	 box-sizing: border-box;
	 -webkit-box-sizing: border-box;
	 -webkit-font-smoothing: antialiased;
	 outline: none;
}

html, body {
	 width: 100%;
	 height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background: $base_bg;
    font-size: $base_font_size;
    color: $base_text_color;
    font-family: $base_font_family;
    font-weight: 400;
    
    &.popup_menu_open {
        overflow: hidden;
     }
     &.popup_open {
        overflow: hidden;
     }
}

a {
	 color: $base_link_color;
	 text-decoration: underline;
	 cursor: pointer;
    
	 &:link {
		 color: $base_link_color;
	 }
 	 &:active {
		 color: $base_link_color;
	 }
	 &:visited {
	 	 color: $base_link_color;
	 }
	 &:hover {
	 	 color: $base_link_color;
		 text-decoration: none; 
	 }
}

.clr, .clear {
	 clear: both;
}

input, textarea, button {
	 font-family: $base_font_family;
}

::-webkit-input-placeholder {
	 color: $base_text_color;
}
:-moz-placeholder { 
	 color: $base_text_color;
}
::-moz-placeholder {
	 color: $base_text_color;
}
:-ms-input-placeholder {  
	 color: $base_text_color;
}

ul {
	 margin: 0;
	 padding: 0;
	 list-style: none;
}

body input:-webkit-autofill, body textarea:-webkit-autofill, body select:-webkit-autofill {
    -webkit-box-shadow: none !important;
    -webkit-text-fill-color: #000 !important;
    background: none !important; 
}

/*Container*/

.wrapper {
     position: relative;
	 overflow: hidden;
}

.container {
	position: relative;
	width: $base_width;
    margin: 0 auto;
    
    @include tablet {
        width: 720px;
    }
    @include mobile {
        width: 300px;
    }
}

/*Grid*/

.row {
    margin-left: -15px;
    margin-right: -15px;

    &:before, &:after {
        content: '';
        clear: both;
        display: block;
    }
}

.col {
    float: left;
    padding: 0 15px;
}
.col-1 {
    width: calc(1/14*100%);
}
.col-2 {
    width: calc(2/14*100%);
}
.col-3 {
    width: calc(3/14*100%);
}
.col-4 {
    width: calc(4/14*100%);
}
.col-5 {
    width: calc(5/14*100%);
}
.col-6 {
    width: calc(6/14*100%);
}
.col-7 {
   width: calc(7/14*100%);
}
.col-8 {
   width: calc(8/14*100%);
}
.col-9 {
   width: calc(9/14*100%);
}
.col-10 {
   width: calc(10/14*100%);
}
.col-11 {
   width: calc(11/14*100%);
}
.col-12 {
   width: calc(12/14*100%);
}
.col-13 {
   width: calc(13/14*100%);
}
.col-14 {
   width: 100%;
}

/*Buttons*/

.bts {
	 &__list {
	 }
}

.btn, a.btn {
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 55px;
    padding: 0 30px;
    line-height: 55px;
    color: $base_white_color;
    font-size: 18px;
    font-weight: 700;
    font-family: $base_font2_family;
    text-transform: uppercase;
    background: $base_link_color;
    border: none;
    transition: all .5s;
    text-align: center;
    border-radius: 27px;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        opacity: 0.9;
    }
}

.owl-nav, .owl-dots {
    &.disabled {
        display: none;
    }
}

/*Forms*/

.form {
    &__group {
        position: relative;
        margin: 0;
    }
    &__field {
        position: relative;

        label.error {
            font-size: 12px;
            padding: 5px 0 0 0;
            text-transform: uppercase;
            color: red;
        }
    }
    &__label {
        font-size: 16px;
        font-weight: 300;
        padding: 0 0 12px 0;
        color: #000;
    }
    &__control {
        display: block;
        width: 100%;
        height: 50px;
        border: none;
        border-bottom: 3px solid #000;
        padding: 0 13px;
        font-size: 16px;
        font-weight: 400;
        font-family: $base_font2_family;

        &::-webkit-input-placeholder {
             color: #000;
             font-weight: 300;
        }
        &:-moz-placeholder { 
             color: #000;
             font-weight: 300;
        }
        &::-moz-placeholder {
             color: #000;
             font-weight: 300;
        }
        &:-ms-input-placeholder {  
             color: #000;
             font-weight: 300;
        }

        &:focus {
            border-bottom-color: #dbb089;
        }
        &.error {
            border-bottom-color: red;
        }
    }
    textarea.form__control {
        padding: 13px;
        height: 90px;
    }
    &__bts {
        padding-top: 38px;

        .btn {
            cursor: pointer;
            width: 100%;
            text-transform: uppercase;
            
            @include mobile {
                font-size: 15px;
            }
        }
    }
    &__success {
        display: none;
        font-size: 20px;

        .text {
            font-size: 20px !important;
            color: #000 !important;
        }
    }
}

/*Preloader*/

.preloader {
     position: fixed;
	 top:0;
	 left:0;
	 width: 100%;
	 height: 100%;
	 z-index: 1000;
	 background: #000;
}